import React, {useEffect, useState} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';

import {models} from 'powerbi-client';
import axios from "axios";

const RenderPowerBi = ({id, userId, filters}) => {
    const [dataDashboard, setDataDashboard] = useState();

    useEffect(() => {
        getDashboard('');
    }, []);

    function getDashboard(param) {
        axios
            .get(
                process.env.REACT_APP_GET_DASHBOARD + id + param,
            )
            .then(res => {
                setDataDashboard(res.data);
            })
            .catch(error => {
                if (param !== "" && param !== null && param !== undefined) {
                    console.log('FAIL FALLO')
                } else {
                    console.log('FAIL FALLO FALSE NO FALLO')
                    let paramBuildNewToken = "?newtoken=1"
                    console.log("urlError: ", process.env.REACT_APP_GET_DASHBOARD + param)
                    getDashboard(paramBuildNewToken)
                }
            });
    }

    return (
        <>
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: dataDashboard && dataDashboard.reportConfig[0].reportId,
                    embedUrl: dataDashboard && dataDashboard.reportConfig[0].embedUrl,
                    accessToken: dataDashboard && dataDashboard.accessToken,
                    tokenType: models.TokenType.Embed,
                    pageView: "fitToWidth",
                    settings: {
                        // background: models.BackgroundType.Transparent,
                        panes: {
                            filters: {
                                visible: false
                            },
                            pageNavigation: {
                                visible: false
                            }
                        },
                        localeSettings: {
                            language: "es",
                            formatLocale: "es-ES"
                        }
                    },
                    filters: filters
                }}
                eventHandlers={
                    new Map([
                        ['loaded', function () {
                            console.log("Report loaded");

                        }],
                        ['rendered', function () {
                            console.log("Report loaded");

                        }],
                        ['error', function (event) {
                            console.log(event.detail);
                        }],
                    ])
                }
                cssClassName={"embed-container"}
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
        </>
    );
}

export default RenderPowerBi
