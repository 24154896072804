import React, {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAnalytics} from '@fortawesome/pro-regular-svg-icons';
import IconButton from "@mui/material/IconButton";
import {useLocation, useNavigate} from "react-router";
import RenderPowerBi from "./RenderPowerBi";
import {BOARDS, FILTER_TO_USE} from "../const";

const MainPowerBI = () => {
        const navigate = useNavigate();
        const {state} = useLocation();
        const filters = state.filters;
        const filterToUse = state.filterToUse;

        useEffect(() => {
            const boardId = state.board.dashboard_id.toString();
            if (filters) {
                if (boardId === BOARDS.RRHH_DARK || boardId === BOARDS.RRHH_LIGHT || (boardId === BOARDS.RRHH_TRACKING && filterToUse === FILTER_TO_USE.Internal)) {
                    filters[0].target = {
                        table: "new_Usuarios_cambios_parquet",
                        column: "id_empresa"
                    }
                }
            } else {
                if (boardId === BOARDS.RRHH || boardId === BOARDS.RRHH_LIGHT || boardId === BOARDS.RRHH_DARK || boardId === BOARDS.RRHH_TRACKING) {
                    navigate('/');
                }
            }
            if (filterToUse === FILTER_TO_USE.External && boardId === BOARDS.RRHH_TRACKING) {
                filters[0].target = {
                    table: "new_Usuarios_cambios_parquet",
                    column: "area_corporativa"
                }
                filters[0].displaySettings.displayName = "Areas"
                filters[1].target = {
                    table: "new_Usuarios_cambios_parquet",
                    column: "id_empresa"
                }
                filters[1].displaySettings.displayName = "Empresa"
            }
        }, [state])


        const renderDashboard = () => (
            <>
                <div className={`main-power-bi color-0${state.board.dashboard_id}`}>
                    <IconButton
                        color="inherit"
                        aria-label="dashboard">
                        <FontAwesomeIcon icon={faAnalytics}/>
                    </IconButton>
                    <h1 style={{display: 'inline'}}>{state.board.dashboard}</h1>
                </div>

                {<RenderPowerBi id={state.board.dashboard_id} userId={state.user.id}
                                filters={filters}/>}
            </>
        );

        return (
            <>
                {renderDashboard()}
            </>
        )
    }
;
export default MainPowerBI;
/*
await getAccessTokenSilently().catch(error => {
    navigate('/');
    logout({returnTo: window.location.origin})
});*/
