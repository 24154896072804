import React, {useEffect, useRef, useState} from 'react';
import '../../assets/styles/components/_autocompleteTags.scss'
import useOutsideClick from "../../hooks/useOutsideClick"; // PENDIENTE FEATURE PARA CERRAR AUTOCOMPLETE CON CLICK FUERA DEL COMPONENTE

function AutocompleteTags({placeholder, data, setSelected, autocompleteError, collaboratorsDefault = []}) {
    //  const wrapperRef = useRef(null);
    //  useOutsideClick(wrapperRef);

    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState('');
    const [tags, setTags] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if (collaboratorsDefault && collaboratorsDefault.length) {
            setTags(collaboratorsDefault)
            setSelected(collaboratorsDefault)
        }
    }, [collaboratorsDefault]);

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);

        const newFilter = data && data.filter((value) => {
            return value.usuario.toLowerCase().includes(searchWord.toLowerCase());
        });

        if (searchWord === '') {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };


    const clearInput = () => {
        setFilteredData([]);
        setWordEntered('');
    };

    const addTags = (e) => {
        if (e.key && e.key === 'Enter' && e.target.value !== '') {
            if (data.length) {
                const existUser = data.filter((value) => value.usuario === e.target.value);
                const newTag = {
                    usuario: e.target.value,
                    idUsuario: existUser.length > 0 ? existUser[0].idUsuario : null
                }
                setErrorMsg(`${!newTag.idUsuario ? '* Uno o más usuarios ingresados no existen' : ''}`);
                setTags([...tags, newTag]);
                setSelected([...tags, newTag]);
                e.target.value = '';
                setWordEntered('');
            } else {
                setErrorMsg('* En este momento no hay datos disponibles de tus colaboradores')
            }
        }
    }

    const addTagsBySelection = (value) => {
        console.log(value)
        setTags([...tags, value]);
        setSelected([...tags, value]);
        setWordEntered('');
    }

    const removeTags = (index) => {
        const list = tags.filter((_, i) => index !== i);
        setTags(list);
        setSelected(list);
        validRemoveInvalid(list);
    }

    const validRemoveInvalid = (list) => {
        const invalidItems = list.filter((item) => item.idUsuario === null)
        setErrorMsg(`${invalidItems.length ? 'Uno o más usuarios ingresados no existen' : ''}`);
    }

    const isDuplicate = (value) => {
        return tags.filter((item) => item.usuario === value).length ? true : false;
    }

    return (
        <>
            <div className='autocomplete-wrap'>
                <div className='autocomplete-wrap__input'>
                    <div
                        className={autocompleteError !== '' && !tags.length ? 'autocomplete-wrap__input__tags error-tag' : 'autocomplete-wrap__input__tags'}>
                        <ul id='tags'>
                            {
                                tags.map((tag, index) => (
                                    <li key={index} className={`tag ${!tag.idUsuario ? 'tag-error' : ''}`}>
                                        <span className='tag-title'>{tag.usuario}</span>
                                        <span className='wrap-icon' onClick={() => removeTags(index)}>
                                            <span className='wrap-icon__close'></span>
                                            <i className='icon-cross'/>
                                        </span>
                                    </li>))
                            }
                        </ul>
                        <input type='text' value={wordEntered} placeholder={tags.length ? '' : placeholder}
                               onChange={handleFilter}
                               onKeyUp={(e) => addTags(e)}/>
                    </div>
                    <div className='autocomplete-wrap__input__icons'>
                        {filteredData.length === 0 ? (
                            <i className='icon-glass'/>
                        ) : (
                            <i className='icon-cross' id='clearIcon' onClick={clearInput}/>
                        )}
                    </div>
                </div>
                {errorMsg !== '' && <p className='error-msg'>{errorMsg}</p>}
                {autocompleteError !== '' && !tags.length && <p className='error-msg'>{autocompleteError}</p>}
                {filteredData.length !== 0 && (
                    <div className='autocomplete-wrap__options'>
                        {filteredData.slice(0, 10).map((value, index) => {
                            return (
                                <a key={index} className='dataItem' onClick={() => addTagsBySelection(value)}>
                                    <p>{value.usuario}</p>
                                </a>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}

export default AutocompleteTags;
