import {useState} from 'react';

const axios = require('axios');

export const callApi = async (body, endpoint) => {
    const response = await axios.put(endpoint, body)
    return response;
};

export const usePut = () => {
    const [isLoadingPut, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const executePut = async (body, endpoint) => {
        try {
            setIsLoading(true);
            const todo = await callApi(body, endpoint);
            setData(todo);
            setIsLoading(false);
            return todo;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoadingPut,
        error,
        data,
        executePut,
    };
};




