import React, { useEffect, useState } from "react";
import "./admin.scss"
import "../../assets/styles/components/_masonry-grid.scss"
import { useNavigate } from "react-router";
import { BiButton } from "../../components/designSystem/BiButton";
import { Messages } from "../../components/designSystem/Messages";
import Masonry from "react-masonry-css";
import { useGet } from "../../hooks/http/useGetData";
import AutocompleteTags from "../../components/designSystem/AutocompleteTags";
import { usePut } from "../../hooks/http/usePut";
import Modal from "../../components/designSystem/Modal/Modal";
import Spinner from "../../components/designSystem/Spinner";
import store from "../../store";
import { COMPANY, ROLE } from "../const";
import { useAuth0 } from '@auth0/auth0-react';

const DeleteCollaborators = () => {
    let data = store.getState();
    const user = data.myPersistReducer.user;

    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const { execute, isLoading } = useGet();
    const { executePut } = usePut();
    const { logout, getAccessTokenSilently } = useAuth0();

    const [autocompleteError, setAutocompleteError] = useState('');
    const [autocompleteUsers, setAutocompleteUsers] = useState([]);
    const [selected, setSelected] = useState([]);
    const [searchResultUsers, setSearchResultUsers] = useState([]);
    const [searchResultUsersInvalid, setSearchResultUsersInvalid] = useState([]);
    const [selectionToDelete, setSelectionToDelete] = useState({});

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    const toggle = () => {
        setActive(!active);
    };

    useEffect(() => {
        (async () => {
            try {
                // await getAccessTokenSilently().catch(error => {
                //     navigate('/');
                //     logout({ returnTo: window.location.origin })
                // });
                let endpoint = `${process.env.REACT_APP_GET_AUTOCOMPLETE_USERS.replace('_compId', user.companyId)}`;
                endpoint = `${endpoint.replace('_query', '')}`;
                let data = await execute(endpoint);
                setAutocompleteUsers(data[0].body);
                //  setAutocompleteUsers(datamock);
            } catch (e) {
                console.log(e)
            }
        })();
    }, [execute]);

    const handleNavigateTo = (path) => {
        navigate(`/admin/${path}`);
    }

    const Card = ({ user }) => (
        <div className='admin__result__card'>
            <h3 className='admin__result__card__title'>{user.username}</h3>

            <div className='admin__result__card__items'>
                {user.areas ? user.areas.map((area, index) => (
                    <Area key={index} area={area} user={user} />
                )) :
                    <p className='admin__result__card__items__no-areas'>Usuario sin permisos</p>
                }
            </div>
        </div>
    )

    const Area = ({ area, user }) => (
        <div>
            <span className={'area'}><i className={'icon-metrics i-metrics'} />&nbsp;&nbsp;{area.name}</span>
            <span className={'delete'} onClick={() => {
                setSelectionToDelete({ area, user })
                toggle();
            }}>Eliminar&nbsp;&nbsp;<i
                    className={'icon-trash i-trash'} /></span>
        </div>
    )

    const search = async () => {
        const valid = getValid();
        let users;
        valid.map(item => {
            users = `${users ? users + ',' : ''}${item.idUsuario}`;
        })
        if (users) {
            let endpoint = `${process.env.REACT_APP_GET_AREAS_BY_USER.replace('_compId', user.companyId)}`;
            endpoint = endpoint.replace('_users', users);
            console.log('endpoint areas by users', endpoint);
            let result = await execute(endpoint);
            console.log('result areas by users', result);
            setSearchResultUsers(result.body.data);
            setSearchResultUsersInvalid(result.body.invalid);
        } else {
            setAutocompleteError('* Para continuar, agrega, al menos, el correo electrónico de un colaborador');
        }
    }

    const getValid = () => {
        const valid = selected.filter((value) => {
            return value.idUsuario !== null;
        });
        if (valid) {
            return valid;
        }
    }

    const removeArea = async (selection) => {
        try {
            const endpoint = `${process.env.REACT_APP_PUT_PERMISSIONS}`;
            let body = {
                companyId: user.companyId,
                userId: selection.user.userId.toString(),
                method: 0
            }
            if (user.companyId === COMPANY.UBITS) {
                body.companyArea = selection.area.id.toString();
            } else {
                body.companyArea = selection.area.name;
            }
            await executePut(body, endpoint);
            await search();
            toggle()
        } catch
        (e) {
            console.log(e)
        }
    }

    return (
        <>
            {user.role === ROLE.RRHH ?
                <>
                    <div className="admin">
                        <div className='admin__title'>
                            <div>
                                <h1>Colaboradores</h1>
                                <p>
                                    Administra las métricas que ven tus colaboradores
                                </p>
                            </div>
                            <div>
                                <BiButton
                                    type={'secondary'}
                                    icon={'icon-plus'}
                                    label={'Crear perfil colaboradores'}
                                    disabled={false}
                                    onClick={() => handleNavigateTo('add')}
                                />
                            </div>
                        </div>
                        <div className='admin__content-delete'>
                            <p className={'admin__content-delete__sub'}>
                                Escribe el correo del colaborador que no verá más las métricas del área corporativa</p>

                            <Messages type={'info'}
                                text={'Busca y selecciona los correos electrónicos de tus colaboradores.'} />

                            <AutocompleteTags placeholder='ingresa el email de tus colaboradores' data={autocompleteUsers}
                                setSelected={setSelected} autocompleteError={autocompleteError} />

                            <div className={'admin__content-delete__search'}>
                                <BiButton
                                    type={'primary'}
                                    icon={'icon-glass'}
                                    label={'Buscar'}
                                    disabled={false}
                                    onClick={() => search()}
                                />
                            </div>
                        </div>
                        <div className='admin__result'>
                            {!isLoading ?
                                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column">
                                    {
                                        searchResultUsers && searchResultUsers.map((user, index) => (
                                            <Card key={index} user={user} />
                                        ))
                                    }
                                    {
                                        searchResultUsersInvalid && searchResultUsersInvalid.map((user, index) => (
                                            <Card key={index} user={user} />
                                        ))
                                    }
                                </Masonry>
                                :
                                <div className='admin__result__spinner'>
                                    <Spinner />
                                </div>
                            }
                        </div>

                        <Modal active={active} toggle={toggle}>
                            <div className='admin__msg-delete'>
                                <p>Al eliminar el
                                    dashboard {selectionToDelete.area?.name.trim()}, <strong>{selectionToDelete.user?.username}</strong> ya
                                    no lo podrá ver
                                </p>
                                <p>¿Deseas eliminarlo?</p>
                                <div className='admin__msg-delete__actions'>
                                    <span onClick={() => toggle()}>No eliminar</span>
                                    <BiButton
                                        type={'secondary'}
                                        label={'Si, eliminar'}
                                        disabled={false}
                                        onClick={async () => {
                                            await removeArea(selectionToDelete)
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </> :
                <h1>Sitio no encontrado</h1>
            }
        </>
    )
}
export default DeleteCollaborators;

