import {createStore, combineReducers} from 'redux';
import dashboardReducer from './dashboards/reducer';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

const storageConfig = {
    key: 'root', // debe tener
    storage: storage, // Mecanismo de caché
    List: ['NOMBRE', 'AGN'] // LOS DATOS ILURRTOS EN LOS DATOS DE PERSIENCIA
}
const myPersistReducer = persistReducer(storageConfig, dashboardReducer);
const reducer = combineReducers({
    myPersistReducer,
});

const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store)

export default store;
