import React, {useEffect} from 'react';
import NavBar from "./NavBar";
import {Outlet} from "react-router-dom";
import "./layout.scss"
import store from "../store";
import {addAssignedBoards, addCompanies, addFilters, collaboratorsToAdd} from "../store/dashboards/actions";

const Main = () => {


    return (
        <div className="main">
            <NavBar ></NavBar>
            <div>
                <Outlet/>
            </div>
        </div>
    )
}

export default Main;
