import React from "react";
import '../../assets/styles/components/_messages.scss'

export const Messages = ({text, type}) => {

    return (
        <div className={`message ${type}`}>
            <i className={`icon-${type}`}/>
            <span>{text}</span>
        </div>
    )
}
/*Type could be info danger success or warning*/
