import React from 'react';
import Root from './Root';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

const AuthCognito = () => {

  const { user, isAuthenticated } = useAuth0();

  return (


    <>{isAuthenticated && (
      <> 
        <Root user={user}/>
      </>
    )}
    </>
  )
}

export default withAuthenticationRequired(AuthCognito);

