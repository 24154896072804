/*const inicialState = {
    payLoad: []
};
const dashboardReducer = (state = inicialState, action) => {
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                user: action.user
            }
        case 'ADD_ASSIGNED_BOARDS':
            return {
                ...state,
                assignedBoards: action.assignedBoards,
            }
        case 'ADD_COMPANIES' :
            return {
                ...state,
                companies: action.companies,
            }
    }
}
export default dashboardReducer;*/


const inicialState = {
    payLoad: []
};
const dashboardReducer = (state = inicialState, action) => {
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                user: action.user
            }
        case 'ADD_ASSIGNED_BOARDS':
            return {
                ...state,
                assignedBoards: action.assignedBoards,
            }
        case 'ADD_COMPANIES' :
            return {
                ...state,
                companies: action.companies,
            }
        case 'ADD_FILTERS' :
            return {
                ...state,
                filters: action.filters,
            }
        case 'COLLABORATORS_TO_ADD' :
            return {
                ...state,
                collaborators: action.collaborators,
            }
    }
    return {
        ...state,
        user: action.user,
        assignedBoards: action.assignedBoards,
        companies: action.companies,
        filters: action.filters,
        collaborators: action.collaborators
    }
}
export default dashboardReducer;

