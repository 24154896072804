import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { faAnalytics } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from "react-router";
import { Breadcrumb } from "./Breadcrumb";
import store from '../store';
import { addAssignedBoards, addCompanies, addFilters, collaboratorsToAdd } from "../store/dashboards/actions";
import { useAuth0 } from '@auth0/auth0-react';

const NavBar = (props) => {

    const { state } = useLocation();
    const { logout } = useAuth0();
    const LogoutButton = () => {

        store.dispatch(addCompanies([]));
        store.dispatch(addAssignedBoards([]));
        store.dispatch(addFilters([]));
        store.dispatch(collaboratorsToAdd([]));

    }

    return (
        <div className="main__navbar">
            <div className="main__navbar__logo">
                <NavLink to="/">
                    <IconButton
                        color="inherit"
                        aria-label="menu">
                        <FontAwesomeIcon icon={faAnalytics} />
                    </IconButton>
                </NavLink>

                <span>Métricas</span>
            </div>
            {state?.board.dashboard && (
                <div className="main__navbar__breadcrumb">
                    <ul>
                        <li><NavLink to="/"> Dashboards &nbsp; / &nbsp; </NavLink></li>
                        <li>
                            {state?.board.dashboard}
                        </li>
                    </ul>
                </div>)
            }

            <Breadcrumb />

            <NavLink to="/" className="main__navbar__logout"
                onClick={() => {
                    LogoutButton()
                    logout({ returnTo: window.location.origin })
                }} >
                <Button>Cerrar sesión <LogoutIcon sx={{ fontSize: 16, cursor: 'pointer' }} /></Button>
            </NavLink>
        </div>
    )
}

export default NavBar;
