import React from 'react';
import ReactDOM from 'react-dom';
import AuthCognito from './features/AuthCognito';

import './index.scss'
import './App.scss'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/index';
import { persistor } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Auth0Provider
                    domain={process.env.REACT_APP_DOMAIN}
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience="https://ubits-api-authorization"
                >
                    <html lang="es" translate="no">
                        <AuthCognito />
                    </html>
                </Auth0Provider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
