import React from "react";
import '../../assets/styles/components/_button.scss'

export const BiButton = ({type, icon, label, disabled, width, onClick}) => {

    const size = {
        width: width
    }
    return (
        <button onClick={!disabled ? onClick : () => {}
        } className={`button_inner ${type} ${size}`}>
            <i className={`l ${icon}`}></i>
            <span className=' t'>{label}</span>
        </button>
    )
}

