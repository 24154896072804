import React from "react";
import {useNavigate} from "react-router";
import feedback from '../../assets/static/feedback.svg'
import {BiButton} from "../../components/designSystem/BiButton";

export const FeedbackToAdd = () => {
    const navigate = useNavigate();

    return (
        <div className='admin__feedback-to-add'>
            <div>
                <img src={feedback} alt=""/>
            </div>
            <div>
                <h2>Tu colaborador ya puede acceder</h2>
                <p>Ahora tus colaboradores pueden ver las métricas que tu elegiste para ellos</p>
                <div>
                    <BiButton
                        type={'secondary'}
                        icon={'icon-plus'}
                        label={'Ir a mis dashboards'}
                        disabled={false}
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
