import {useCallback, useState} from "react";

const axios = require('axios');

export const getCallApi = async (options) => {
    const response = await axios(options)
    const data = await response.data;
    return data;
};

export const useGet = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const execute = async (options = {}) => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            const todos = await getCallApi(options);
            setData(todos);
            setIsLoading(false);
            return todos;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []),
    };
};
