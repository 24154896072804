import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnalytics} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import {useNavigate} from "react-router";

const CardHome = ({item, user, companiesByUser, filters, filterToUse}) => {
    const navigate = useNavigate();


    const handleClick = (item) => {
        navigate('board', {
            state: {
                board: item,
                user: user,
                companiesByUser: companiesByUser,
                filters: filters,
                filterToUse: filterToUse
            }
        });
    }

    return (
        <div onClick={() => handleClick(item)} className={`card-dashboard color-0${item.dashboard_id}`}>
            <div className="card-dashboard__top">
                <IconButton
                    color="inherit"
                    aria-label="menu">
                    <FontAwesomeIcon icon={faAnalytics}/>
                </IconButton>

                <span>Dashboard</span>
            </div>

            <div className="card-dashboard__bottom">
                <h1>{item?.dashboard}</h1>
                <p>{item.description}</p>
            </div>
        </div>
    );
}
export default CardHome;
