import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";

import store from '../../store';
import {connect} from 'react-redux';
import {addAssignedBoards, addCompanies, addFilters, addUser} from '../../store/dashboards/actions';

import "./dashboard.scss"
import homeFrame from '../../assets/static/homeFrame.svg'
import {useGet} from "../../hooks/http/useGetData";

import {BiButton} from "../../components/designSystem/BiButton";
import CardHome from "./CardHome";
import {BOARDS, FILTER_TO_USE, ROLE} from "../const";
import {useAuth0} from '@auth0/auth0-react';

const Dashboard = (props) => {
    const navigate = useNavigate();
    const cognitoUser = props.user;
    const {execute, isLoading} = useGet();
    const {logout, getAccessTokenSilently} = useAuth0();
    const [user, setUser] = useState();
    const [companiesByUser, setCompaniesByUser] = useState();
    const [assigned, setAssigned] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [filters, setFilters] = useState([]);

    const [filterToUse, setFilterToUse] = useState(FILTER_TO_USE.Internal)

    useEffect(() => {
        (async () => {
            try {
                // await getAccessTokenSilently().catch(error => {
                //     navigate('/');
                //     logout({ returnTo: window.location.origin })
                // });
                let user = await execute(`${process.env.REACT_APP_GET_ID_USER}${cognitoUser.username.replaceAll('#', '')}`);
                let respCompaniesByUser = await execute(process.env.REACT_APP_GET_COMPANIES.replace("userId", user[0].id));
                setUser(user[0]);
                store.dispatch(addUser(user[0]));
                await setupRRHHUser(user[0], respCompaniesByUser);
            } catch (e) {
                console.log(e)
            }
        })();
    }, [execute]);


    const setupRRHHUser = async (user, companies) => {
        const companiesByUser = companies && companiesToNumberList(companies);
        setCompaniesByUser(companiesByUser);

        if (user.role === ROLE.RRHH) {
            //si rrhh no tiene companies, se va con user company id
            let metadata = await execute(`${process.env.REACT_APP_GET_METADATA_DASHBOARDS.replace('dashboards_ids', `${BOARDS.RRHH_TRACKING}`)}`);
            setAssigned([ BOARDS.RRHH_TRACKING]);
            setMetadata(metadata[0].body);

            let filters = [
                {
                    target: {
                        table: "user_full",
                        column: "id_empresa"
                    },
                    operator: "In",
                    values: [user.companyId],
                    displaySettings: {
                        isLockedInViewMode: true,
                        displayName: "Empresa"
                    }
                },
            ]
            store.dispatch(addFilters(filters));
            store.dispatch(addAssignedBoards([ BOARDS.RRHH_TRACKING]));
            store.dispatch(addCompanies(companiesByUser));
            setFilters(filters)
        } else {
            await setupNoBoardsAndNoCompanies(user, companiesByUser);
        }
    }

    const companiesToNumberList = (companies) => {
        let companiesByUser = companies[0].companies !== '0' ? companies[0].companies.split(',').map(item => Number(item)) : [];
        store.dispatch(addCompanies(companiesByUser));
        return companiesByUser;
    }

    const setupNoBoardsAndNoCompanies = async (user, companies) => {
        let respAssignedBoards = await execute(`${process.env.REACT_APP_GET_ASSIGNED_DASHBOARDS.replace('userId', user.id)}`);
        let assignedBoards = respAssignedBoards[0].dashboard !== '0' ? respAssignedBoards[0].dashboard.split(',').map(item => Number(item)) : []

        if (!assignedBoards.length && !companies.length) {
            let endpointAreasByUser = `${process.env.REACT_APP_GET_AREAS_BY_USER.replace('_users', user.id)}`;
            endpointAreasByUser = endpointAreasByUser.replace('_compId', user.companyId)
            let areasByUser = await execute(endpointAreasByUser);
            if (areasByUser && areasByUser.body.data[0] && areasByUser.body.data[0].areas.length) {
                let metadata = await execute(`${process.env.REACT_APP_GET_METADATA_DASHBOARDS.replace('dashboards_ids', `${BOARDS.RRHH_TRACKING}`)}`);
                setAssigned([ BOARDS.RRHH_TRACKING]);
                setMetadata(metadata[0].body);
                store.dispatch(addAssignedBoards([  BOARDS.RRHH_TRACKING]));


                console.log('AREAS DEL USUARIO ', areasByUser.body.data[0].areas.map(item => item.name));
                // await setRRHHBoard();
                setFilterToUse(FILTER_TO_USE.External)

                let filters = [
                    {
                        target: {
                            table: "user_full",
                            column: "area_corporativa"
                        },
                        operator: "In",
                        values: areasByUser.body.data[0].areas.map(item => item.name),
                        displaySettings: {
                            isLockedInViewMode: true,
                            displayName: "Areas"
                        }
                    },
                    {
                        target: {
                            table: "user_full",
                            column: "id_empresa"
                        },
                        operator: "In",
                        values: [user.companyId],
                        displaySettings: {
                            isLockedInViewMode: true,
                            displayName: "Empresa"
                        }
                    },
                ]
                store.dispatch(addFilters(filters));
                setFilters(filters)
            }
        } else {
            if (assignedBoards.length) {
                let metadata = await execute(`${process.env.REACT_APP_GET_METADATA_DASHBOARDS.replace('dashboards_ids', respAssignedBoards[0].dashboard)}`);
                setAssigned(assignedBoards);
                setMetadata(metadata[0].body);
                store.dispatch(addAssignedBoards(assignedBoards));
            } else {
                let metadata = await execute(`${process.env.REACT_APP_GET_METADATA_DASHBOARDS.replace('dashboards_ids', `${BOARDS.RRHH_TRACKING}`)}`);
                setAssigned([ BOARDS.RRHH_TRACKING]);
                setMetadata(metadata[0].body);
                store.dispatch(addAssignedBoards([ BOARDS.RRHH_TRACKING]));
            }

            if (companies.length) {
                let filters = [
                    {
                        target: {
                            table: "user_full",
                            column: "id_empresa"
                        },
                        operator: "In",
                        values: companies,
                        displaySettings: {
                            isLockedInViewMode: true,
                            displayName: "Empresa"
                        }
                    }
                ]
                store.dispatch(addFilters(filters));
                setFilters(filters)
            }
        }
    }

    const setFilterWithAreas = (areas) => {
        console.log('areas completas', areas);
        let filters = [
            {
                target: {
                    table: "user_full",
                    column: "area_corporativa"
                },
                operator: "In",
                values: areas,
                displaySettings: {
                    isLockedInViewMode: true,
                    displayName: "Areas"
                }
            },
            {
                target: {
                    table: "user_full",
                    column: "id_empresa"
                },
                operator: "In",
                values: [user.companyId],
                displaySettings: {
                    isLockedInViewMode: true,
                    displayName: "Empresa"
                }
            },
        ]
        store.dispatch(addFilters(filters));
        setFilters(filters)

    }

    const setRRHHBoard = async () => {
        let metadata = await execute(`${process.env.REACT_APP_GET_METADATA_DASHBOARDS.replace('dashboards_ids', BOARDS.RRHH)}`);
        setAssigned([BOARDS.RRHH]);
        setMetadata(metadata[0].body);
        let filters = [
            {
                target: {
                    table: "user_full",
                    column: "id_empresa"
                },
                operator: "In",
                values: [user.companyId],
                displaySettings: {
                    isLockedInViewMode: true,
                    displayName: "Empresa"
                }
            },
        ]
        store.dispatch(addFilters(filters));
        setFilters(filters)
    }

    const handleNavigateTo = (path) => {
        navigate(`admin/${path}`);
    }

    return (
        <>
            {isLoading ?
                <h1 className="dashboards-home">Cargando dashboards...</h1>
                :
                <>
                    {!assigned.length && (
                        <h1 className="dashboards-home">No tienes dashboards asignados.</h1>
                    )
                    }
                    {assigned.length ? (
                        <div className="dashboards-home">

                            <div className="dashboards-home__title">
                                <div>
                                    <h1>DASHBOARDS</h1>
                                    <p>
                                        Analiza tus usuarios, revisa el progreso de tus proyectos y descarga tus datos
                                    </p>
                                </div>

                                {user?.role === 'RRHH' &&
                                    <div className="dashboards-home__title__actions">
                                        <BiButton
                                            type={'primary'}
                                            icon={'icon-user'}
                                            label={'Colaboradores'}
                                            disabled={false}
                                            onClick={() => handleNavigateTo('manage')}
                                        />

                                        <BiButton
                                            type={'secondary'}
                                            icon={'icon-plus'}
                                            label={'Crear perfil colaboradores'}
                                            disabled={false}
                                            onClick={() => handleNavigateTo('add')}
                                        />
                                    </div>
                                }

                            </div>

                            <div className="dashboards-home__content">
                                <div className="dashboards-home__content__image">
                                    <img src={homeFrame} alt=""/>
                                    <p>Ofrecemos <strong>métricas</strong> exactas y confiables</p>
                                </div>
                                <div className="dashboards-home__content__grid-card">
                                    {metadata.length && metadata.map((item, id) => {
                                        return (<CardHome key={id} item={item} user={user}
                                                          companiesByUser={companiesByUser} filters={filters}
                                                          filterToUse={filterToUse}/>)
                                    })}
                                </div>
                            </div>

                        </div>
                    ) : ''
                    }
                </>
            }
        </>
    )
}
const mapDispatchToProps = {
    addUser,
    addAssignedBoards,
    addCompanies,
    addFilters
}
export default connect(null, mapDispatchToProps)(Dashboard)
