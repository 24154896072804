const addUser = (payload) => {
    return (
        {
            type: "ADD_USER",
            user: payload,
        }
    )
}

const addAssignedBoards = (payload) => {
    return (
        {
            type: "ADD_ASSIGNED_BOARDS",
            assignedBoards: payload,
        }
    )
}

const addCompanies = (payload) => {
    return (
        {
            type: "ADD_COMPANIES",
            companies: payload
        }
    )
}

const addFilters = (payload) => {
    return (
        {
            type: "ADD_FILTERS",
            filters: payload
        }
    )
}

const collaboratorsToAdd = (payload) => {
    return (
        {
            type: "COLLABORATORS_TO_ADD",
            collaborators: payload
        }
    )
}

export {addUser, addAssignedBoards, addCompanies, addFilters, collaboratorsToAdd};

