import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import React from "react";

export const Breadcrumb = () => {

    const location = useLocation();
    const currentRoute = location.pathname.split('/',);


    function ItemList(item, i) {
        const lengthRoutes = currentRoute.length - 1;
        let label = item === 'admin' ? 'Dashboards' : '' || item === 'add' || item === 'manage' ? 'Colaboradores' : '';
        return (
            <>
                {label !== '' &&
                <>
                    <NavLink to={label === 'Dashboards' ? '/' : 'admin/' + item}>&nbsp;{label}</NavLink>&nbsp;
                    <span>{i > 0 && i < lengthRoutes ? '/' : ''}</span>
                </>
                }
            </>
        )
    }

    return (
        <div className="main__navbar__breadcrumb">
            <ul>
                {currentRoute.map((item, index) =>
                    <li key={index}>
                        {ItemList(item, index)}
                    </li>
                )}
            </ul>
        </div>
    )
}

