import React, {Component} from 'react';
import Portal from './Portal';
import '../../../assets/styles/components/_modal.scss'

export class Modal extends Component {
    render() {
        const {children, toggle, active, size} = this.props;
        return (
            <Portal>
                {active && (
                    <div className='modal'>
                        <div className='modal__window' style={size}>
                            {children}
                        </div>
                        <div className='modal__background' onClick={toggle}/>
                    </div>
                )
                }
            </Portal>
        );
    }
}

export default Modal;

