import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const portalRoot = document.getElementById('portal')

class Portal extends Component {

    constructor() {
        super();
        this.e = document.createElement('div');
    }

    componentDidMount() {
        portalRoot.appendChild(this.e);
    }

    componentWillUnmount() {
        portalRoot.removeChild(this.e);
    }

    render() {
        const {children} = this.props;
        return ReactDOM.createPortal(children, this.e)
    }
}

export default Portal;