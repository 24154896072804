import React from 'react';
import { useEffect, useState } from 'react';
import { Route, HashRouter, Routes, Router, BrowserRouter } from 'react-router-dom'
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Main from '../layout/Main';
import MainPowerBI from './render/MainPowerBI'
import Dashboard from './home/Dashboard';
import AddCollaborators from './admin-add/AddCollaborators';
import DeleteCollaborators from "./admin-delete/DeleteCollaborators";
import { useAuth0 } from '@auth0/auth0-react';

function useToken() {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState(null);

    useEffect(() => { getAccessTokenSilently().then(setToken) }, [])

    return token;
}


function Root({ user }) {
    const jwt = useToken()
    if (jwt) {
        sessionStorage.clear();
        axios.interceptors.request.use(function (config) {
            const token = 'Bearer ' + jwt;
            config.headers.Authorization = token;
            return config;
        });
    }

    return (
        <>
            {jwt && <BrowserRouter>
                <Routes>
                    <Route path='' element={<Main />}>
                        <Route path='/' element={<Dashboard user={user} />} />
                        <Route path='board' element={<MainPowerBI user={user} />} />

                        <Route path='admin/add' element={<AddCollaborators />} />
                        <Route path='admin/manage' element={<DeleteCollaborators user={user} />} />

                        <Route path='*' element={<h1>Sitio no encontrado</h1>} />

                    </Route>
                </Routes>
            </BrowserRouter>}
        </>
    )
}

export default Root
