export const ROLE = {
    RRHH: 'RRHH',
    user: 'user'
}

export const BOARDS = {
    RRHH_DARK: '3',
    RRHH: '5',
    RRHH_TRACKING: '6',
    RRHH_LIGHT: '7',
}

export const FILTER_TO_USE = {
    External: 'External',
    Internal: 'Internal',
}
export const COMPANY = {
    UBITS: 25
}

// STATUS MESSAGES

export const STATUS_MSG = {
    success: 200
}


