import React, { useEffect, useState } from "react";

import "../../assets/styles/components/_checkbox.scss"
import "../admin-add/addCollaborators.scss"

import { BiButton } from "../../components/designSystem/BiButton";
import { Messages } from "../../components/designSystem/Messages";
import AutocompleteTags from "../../components/designSystem/AutocompleteTags";
import { FeedbackToAdd } from "./FeedbackToAdd";
import Spinner from "../../components/designSystem/Spinner";


import { useNavigate } from "react-router";
import { useGet } from "../../hooks/http/useGetData";
import { usePut } from "../../hooks/http/usePut";

import store from "../../store";
import { collaboratorsToAdd } from "../../store/dashboards/actions";
import { connect } from "react-redux";
import { COMPANY, ROLE, STATUS_MSG } from "../const";
import OverlapScreen from "../../components/designSystem/Modal/OverlapScreen";
import { useAuth0 } from '@auth0/auth0-react';

const AddCollaborators = () => {
    const navigate = useNavigate();
    let data = store.getState();
    const user = data.myPersistReducer.user;
    const collaborators = data.myPersistReducer.collaborators;

    const [stepActive, setStepActive] = useState(11);
    const [firstCheck, setFirstCheck] = useState();
    const [secondCheck, setSecondCheck] = useState();
    const [finish, setFinish] = useState(false);

    const [selectedToAdd, setSelectedToAdd] = useState({});
    const { logout, getAccessTokenSilently } = useAuth0();

    const RenderFirstStep = ({ setSelectedToAdd, }) => {
        const { execute, isLoading } = useGet();

        const [autocompleteError, setAutocompleteError] = useState('');
        const [autocompleteUsers, setAutocompleteUsers] = useState([]);
        const [selected, setSelected] = useState([]);


        useEffect(() => {
            (async () => {
                try {
                    // await getAccessTokenSilently().catch(error => {
                    //     navigate('/');
                    //     logout({ returnTo: window.location.origin })
                    // });
                    let endpoint = `${process.env.REACT_APP_GET_AUTOCOMPLETE_USERS.replace('_compId', user.companyId)}`;
                    endpoint = `${endpoint.replace('_query', '')}`;
                    let data = await execute(endpoint);
                    setAutocompleteUsers(data[0].body);
                    // setAutocompleteUsers(datamock);
                } catch (e) {
                    console.log(e)
                }
            })();
        }, []);

        const nextStep = () => {
            setStepActive(12);
            setFirstCheck('icon-check');
        }

        const next = async () => {
            const valid = getValid();
            let users;
            store.dispatch(collaboratorsToAdd(valid));
            valid.map(item => {
                users = `${users ? users + ',' : ''}${item.idUsuario}`;
            })
            if (users) {
                setSelectedToAdd({ users: users })
                nextStep();
            } else {
                setAutocompleteError('* Para continuar, agrega, al menos, el correo electrónico de un colaborador');
            }
        }

        const getValid = () => {
            const valid = selected.filter((value) => {
                return value.idUsuario !== null;
            });
            if (valid) {
                return valid;
            }
        }

        return (
            <>

                <p className={'admin__content-add__sub'}>
                    Agrega el correo electrónico de tus colaboradores</p>

                <p className={'admin__content-add__desc'}>Crea un perfil para los colaboradores, así pueden ver
                    las
                    métricas
                    de las áreas que selecciones
                </p>

                <Messages type={'info'} text={'Busca y selecciona los correos electrónicos de tus colaboradores'} />
                <AutocompleteTags placeholder='ingresa el email de tus colaboradores' data={autocompleteUsers}
                    setSelected={setSelected} autocompleteError={autocompleteError}
                    collaboratorsDefault={collaborators} />

                <div className={'admin__content-add__actions'}>
                    <div><span onClick={() => navigate('/')} className={'exit'}>Salir</span></div>
                    <BiButton
                        type={'primary'}
                        icon={'icon-right-arrow'}
                        label={'Siguiente'}
                        disabled={false}
                        onClick={async () => {
                            await next();
                        }}
                    />
                </div>

            </>
        )
    }

    const RenderSecondStep = ({ selectedToAdd }) => {
        const { executePut, isLoadingPut } = usePut();
        const { execute, isLoading } = useGet();
        const [errorMsg, setErrorMsg] = useState('');

        const [areasByCompany, setAreasByCompany] = useState([]);

        useEffect(() => {
            (async () => {
                try {
                    let endpoint = `${process.env.REACT_APP_GET_AREAS_BY_COMPANY.replace('_compId', user.companyId)}`;
                    let respAreas = await execute(endpoint);
                    setAreasByCompany(respAreas.areas)
                } catch (e) {
                    console.log(e)
                }
            })();
            setAreas();
        }, []);

        const setAreas = () => {
            if (areasByCompany.length) {
                setAreasByCompany(
                    areasByCompany.map(item => {
                        return {
                            select: false,
                            id: item.id,
                            name: item.name
                        };
                    })
                );
            }
        }

        const nextStep = () => {
            setStepActive(22);
            setSecondCheck('icon-check');
            setFinish(true);
        }

        const getSelectedCompanies = () => {
            let companies = '';
            areasByCompany.filter(item => item.select).map(item => companies = `${companies ? companies + ',' : ''}${item.id}`);
            return companies;
        }

        const getSelectedAreas = () => {
            let areas = '';
            areasByCompany.filter(item => item.select).map(item => areas = `${areas ? areas + ',' : ''}${item.name}`);
            return areas
        }

        const addAreasToCollaborators = async (users) => {
            const companiesOrAreas = user.companyId === COMPANY.UBITS ? getSelectedCompanies() : getSelectedAreas();
            if (companiesOrAreas !== '') {
                try {
                    const endpoint = `${process.env.REACT_APP_PUT_PERMISSIONS}`;
                    const body = {
                        companyId: user.companyId,
                        userId: users,
                        companyArea: companiesOrAreas.toString(),
                        method: 1
                    }
                    console.log('add permissions', body);
                    const response = await executePut(body, endpoint);
                    console.log('response permissions', response);
                    if (response.status === STATUS_MSG.success) {
                        store.dispatch(collaboratorsToAdd([]));
                        await sendEmails();
                        nextStep()
                    }
                } catch (e) {
                    setErrorMsg('No fue posible crear perfil, por favor inténtalo más tarde');
                }
            } else {
                setErrorMsg('Por favor selecciona al menos un área');
            }
        }

        const sendEmails = async () => {
            let emails = '';
            collaborators.map(item => {
                emails = `${emails ? emails + ',' : ''}${item.usuario}`
            });

            try {
                const endpoint = `${process.env.REACT_APP_PUT_SEND_CONFIRMATION_EMAILS}`;
                console.log(endpoint);
                const body = {
                    Emails: emails
                }
                console.log('body send emails', body);
                const response = await executePut(body, endpoint);
                console.log('response send emails', response);

            } catch (e) {
                console.log(e)
            }
        }

        const selectAll = (value) => {
            setAreasByCompany(
                areasByCompany.map(d => {
                    d.select = value;
                    return d;
                })
            )
        }

        const renderCheckboxCard = (item, i) => (
            <div className='admin__content-add__areas__card' key={i}>
                <label className='container'>
                    <input
                        onChange={event => {
                            let checked = event.target.checked;
                            setAreasByCompany(
                                areasByCompany.map(data => {
                                    if (item.id === data.id) {
                                        data.select = checked;
                                    }
                                    return data;
                                })
                            );
                        }}
                        type="checkbox"
                        checked={item.select}
                        value={item.select}
                    />
                    <span className='checkmark' />
                </label>
                <div className='text'>{item.name}</div>
                <div className='icon-metrics'><i className='i-metrics' /></div>
            </div>
        )

        return (
            <>
                <p className='admin__content-add__second-step-title'><strong>Selecciona las
                    métricas</strong> que
                    quieres compartir</p>

                {isLoading &&
                    <div className='admin__content-add__spinner content-spinner'>
                        <Spinner />
                    </div>
                }

                <div className='admin__content-add__options'>
                    {!isLoading &&
                        <>
                            <span onClick={() => selectAll(true)}>Seleccionar todo</span>
                            <span onClick={() => selectAll(false)}>Deshacer selección</span>
                        </>
                    }
                </div>

                <div className='admin__content-add__areas'>
                    {!isLoading &&
                        <>
                            {areasByCompany && areasByCompany.map((item, i) => (
                                renderCheckboxCard(item, i)
                            ))}
                        </>
                    }
                </div>

                <div className='box-actions'>
                    <div>
                        <span onClick={() => {
                            if (!finish) {
                                setStepActive(11);
                                setFirstCheck(null);
                            }
                        }} className={'exit'}>Atrás</span>
                        <BiButton
                            type={'primary'}
                            icon={'icon-plus'}
                            label={'Crear perfil'}
                            onClick={() =>
                                addAreasToCollaborators(selectedToAdd.users)}
                        />
                    </div>

                </div>
                <div className='admin__content-add__error'>
                    {errorMsg !== '' && <p className='admin__content-add__error__msg'>{errorMsg}</p>}
                </div>


                <OverlapScreen active={isLoadingPut}>
                    <div className='content-spinner'>
                        <Spinner />
                    </div>
                </OverlapScreen>
            </>
        )
    }

    return (
        <>
            {user.role === ROLE.RRHH ?
                <div className="admin">
                    <div className='admin__content-add'>
                        <div className={'stepper'}>
                            <div>
                                <div className={'bridge'} />
                                <div onClick={() => {
                                    if (!finish) {
                                        setStepActive(11);
                                        setFirstCheck(null);
                                    }
                                }}
                                    className={`stepper__first icon-circle i ${stepActive === 11 ? 'active' : stepActive === 12 || (stepActive === 12 || stepActive === 22) ? 'check' : ''}`}>
                                    <span className={firstCheck}>{!firstCheck ? '1' : ''}</span>
                                </div>
                                <div
                                    className={`stepper__first icon-circle i ${stepActive === 12 ? 'active' : stepActive === 22 ? 'check' : ''}`}>
                                    <span className={secondCheck}>{!secondCheck ? '2' : ''}</span>
                                </div>
                            </div>
                        </div>
                        {stepActive === 11 && !finish && (
                            <RenderFirstStep setSelectedToAdd={setSelectedToAdd} />
                        )
                        }

                        {stepActive === 12 && !finish && (
                            <RenderSecondStep selectedToAdd={selectedToAdd} />
                        )
                        }

                        {finish && (
                            <FeedbackToAdd />
                        )
                        }
                    </div>
                </div> :
                <h1>Sitio no encontrado</h1>
            }
        </>
    )
}


const mapDispatchToProps =
{
    collaboratorsToAdd
}
export default connect(null, mapDispatchToProps)(AddCollaborators)
